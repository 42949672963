import AjaxModel from './AjaxModel.js';

var Mongo = Object.create(AjaxModel);

Mongo.model = 'core/mongo';

Mongo.runCommand = function(command) {
    var self = this;
    const config = {
        method: 'post',
        url: self.server + self.model + '/runCommand',
        data: {comando: command}
    };
    return self.sendRequest(config);
}

export default Mongo;