import axios from 'axios';

const AjaxModel = {
    server: process.env.VUE_APP_SERVER,
    serverSecurity: process.env.VUE_APP_SECURITY_SERVER,
    clientId: process.env.VUE_APP_CLIENT_ID,
    clientSecret: process.env.VUE_APP_CLIENT_SECRET,
    model: '',
    getList: function(params, modelIn) {
        const self = this;
        params = params || {}
        const paramsDefault = {
            // currentPage: (params.page || 1) - 1,
            // limit: params.limit,
            // sortBy: params.order,
            // sortDesc: params.orderDir == 'DESC' ? true : false,
            // complete: params.complete,
            // projection: params.projection,
            // operation: params.operation
        };

        const config = {
            method: 'post',
            url: self.server + (modelIn || self.model) + '/search',
            headers: { 
              'Content-Type': params.contentType || 'application/json'
            },
            data : params.data,
            params: paramsDefault
        };

        return self.sendRequest(config);
    },
    // getCombo: function(params, shownFields = []){
    //     var self = this;
    //     var comboParams = Object.assign({}, params);
    //     if(shownFields.length){
    //         comboParams.projection = shownFields.join(",");
    //     }
    //     return self.getList(comboParams);
    // },
    get: function(id, modelIn) {
        var self = this;
        const config = {
            method: 'get',
            url: self.server + (modelIn || self.model) + '/' + id
        };
        return self.sendRequest(config);
    },
    getFirst: function(modelIn) {
        var self = this;
        const config = {
            method: 'get',
            url: self.server + (modelIn || self.model) + '/first'
        };
        return self.sendRequest(config);
    },
    put: function(id, data, modelIn) {
        var self = this;
        const config = {
            method: 'put',
            url: self.server + (modelIn || self.model) + '/' + id,
            data: data
        };
        return self.sendRequest(config);
    },
    post: function(data, modelIn) {
        var self = this;
        const config = {
            method: 'post',
            url: self.server + (modelIn || self.model),
            data: data
        };
        return self.sendRequest(config);
    },
    save: function(data, modelIn, forceCreate = false) {
        var self = this;
        if (data._id && !forceCreate) {
            return self.put(data._id, data, modelIn);
        } else {
            return self.post(data, modelIn);
        }
    },
    delete: function(id, modelIn) {
        var self = this;
        const config = {
            method: 'delete',
            url: self.server + (modelIn || self.model) + '/' + id
        };
        return self.sendRequest(config);
    },
    // define: function(params, forceReload){
    //     var self = this;
    //     var forceReload = typeof forceReload == "undefined" || forceReload ? true : false;
    //     params = params || {};
    //     var params = {
    //         url: self.server_movilidad + self.prefix + self.model + '/define',
    //         method: 'GET',
    //         data: {}
    //     };
    //     return self.sendRequest(params, forceReload);
    // },
    // getDefinePrepared: function(params, forceReload){
    //     var self = this;
	// 	return self.define(params, forceReload).then(res => {
    //         return self._prepareDefinition(res);
    //     });
    // },
    // getDefineCombo: function(params, forceReload){
    //     var self = this;
	// 	return self.getDefinePrepared(params, forceReload).then(valores => {
    //         return self._tratarObjeto(valores).map(valor => {
    //             return {name:valor.value, content:valor.name};
    //         });
    //     });
    // },
	// _prepareDefinition(property, parent){
	// 	var self = this;
	// 	var preparedProp = {};
	// 	var propertyData = {};
	// 	if(parent){
	// 		propertyData = Object.assign({},property);
	// 		propertyData.property = (parent.property) ? `${parent.property}.${propertyData.property}` : propertyData.property;
	// 		propertyData.name = (parent.name) ? `${parent.name} - ${propertyData.name}` : propertyData.name;
	// 	}
	// 	if(property.children && property.children.length){
	// 		property.children.forEach(child => {
	// 			preparedProp[child.property] = self._prepareDefinition(child, propertyData);
	// 		});
	// 	} else {
	// 		preparedProp[propertyData.property] = propertyData.name;
	// 	}
	// 	return preparedProp;
	// },
    // _tratarObjeto: function(objeto, pwd){
    //     var self = this;
    //     var valores = [];
    //     for(const i in objeto){
    //         const prop = objeto[i];
    //         if(typeof prop == "object"){
    //             const nuevos = self._tratarObjeto(prop);
    //             valores = valores.concat(self._tratarObjeto(prop));
    //         } else {
    //             const position = pwd ? `${pwd}.${i}` : i;
    //             const nuevo = {name:position,value:prop};
    //             valores.push(nuevo);
    //         }
    //     }
    //     return valores;
    // },
    // download: function(url, name) {
    //     var self = this;
    //     params = params || {};
    //     var params = {
    //         url: url,
    //         method: 'GET',
    //         xhrFields: {'responseType': 'arraybuffer'}
    //     };
    //     return new Promise((resolve, reject) => {
    //         self.request({
    //             url: url,
    //             method: 'GET',
    //             crossDomain: true,
    //             xhrFields: { 'responseType': 'arraybuffer' },
    //             success: function(data) {
    //                 var blob = new Blob([data]);
    //                 if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    //                     window.navigator.msSaveOrOpenBlob(blob, name);
    //                 } else {
    //                     var link = document.createElement('a');
    //                     link.href = window.URL.createObjectURL(blob);
    //                     link.download = name;
    //                     link.target = '_blank';
    //                     link.click();
    //                     URL.revokeObjectURL(link.href);
    //                 }
    //                 resolve();
    //             },
    //             error: function(error) {
    //                 reject(error);
    //             }
    //         });
    //     });
    // },
    // getBase64: function(url) {
    //     var self = this;
    //     params = params || {};
    //     var params = {
    //         url: url,
    //         method: 'GET',
    //         xhrFields: {'responseType': 'arraybuffer'}
    //     };
    //     return new Promise((resolve, reject) => {
    //         self.request({
    //             url: url,
    //             method: 'GET',
    //             crossDomain: true,
    //             xhrFields: { 'responseType': 'arraybuffer' },
    //             success: function(arrayBuffer) {
    //                 let array = new Uint8Array(arrayBuffer);
    //                 let base64 = btoa(
    //                     array.reduce((data, byte) => data + String.fromCharCode(byte), '')
    //                   );
            
    //                 // let b642 = arrayBuffer.toString('base64');
    //                 resolve({base64, array});
    //             },
    //             error: function(error) {
    //                 reject(error);
    //             }
    //         });
    //     });
    // },
    // exportExcel: function(params) {
    //     var self = this;
    //     params = params || {};
    //     var name = params.name || model + 's.xlsx';
    //     return new Promise((resolve, reject) => {
    //         self.request({
    //             url: self.server_movilidad + self.prefix + self.model + '/excel',
    //             method: 'POST',
    //             contentType: 'application/json',
    //             crossDomain: true,
    //             xhrFields: { 'responseType': 'arraybuffer' },
    //             data: params.filter || {},
    //             success: function(data) {
    //                 var blob = new Blob([data]);
    //                 if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    //                     window.navigator.msSaveOrOpenBlob(blob, name);
    //                 } else {
    //                     var link = document.createElement('a');
    //                     link.href = window.URL.createObjectURL(blob);
    //                     link.download = name;
    //                     link.target = '_blank';
    //                     link.click();
    //                     URL.revokeObjectURL(link.href);
    //                 }
    //                 resolve();
    //             },
    //             error: function(error) {
    //                 reject(error);
    //             }
    //         });
    //     });
    // },
    sendRequest: function(config, callbackIn, errorCallbackIn, completeCallbackIn){
        const completeCallback = completeCallbackIn || function() {};
        const promise = new Promise(function(resolve, reject) {
            const callback = callbackIn || function(res) {
                if (res.data.error) {
                    reject(res.data);
                } else {
                    resolve(res.data);
                }
            };
            const errorCallback = errorCallbackIn || function(e) {
                let err = "Error de llamada a servidor, compruebe su conexión.";
                if (e && e.response && (e.response.status == 403 || e.response.status == 401) && window.keycloak) {
                    window.keycloak.logout();
                }
                if (e.response && e.response.data && e.response.data.error_description) {
                    err = e.response.data.error_description;
                }
                reject(err);
            }
            axios(config).then(callback).catch(errorCallback).then(completeCallback);
        });
        return promise;
    }
}

export default AjaxModel;