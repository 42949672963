import Vue from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import axios from 'axios';
import 'roboto-fontface/css/roboto/roboto-fontface.css';
import '@mdi/font/css/materialdesignicons.css';
import Home from './js/ajax/Home';

import vTitle from 'vuejs-title'
Vue.use(vTitle)

import VueSimpleAlert from "vue-simple-alert";
Vue.use(VueSimpleAlert);

import VueApexCharts from 'vue-apexcharts'
Vue.use(VueApexCharts)

Vue.component('apexchart', VueApexCharts)




Vue.config.productionTip = process.env.NODE_ENV == 'production';

const options = {
  isEnabled: true,
  logLevel : Vue.config.productionTip  ? 'error' : 'debug',
  stringifyArguments : false,
  showLogLevel : true,
  showMethodName : true,
  separator: '|',
  showConsoleColors: true
};

import VueLogger from 'vuejs-logger';
Vue.use(VueLogger, options);

const moment = require('moment')
require('moment/locale/es')

Vue.use(require('vue-moment'), {
  moment
});

Vue.directive('roles', {
  bind: function(el, binding, vnode) {
      // el.setAttribute("data-initial-display", el.style.display == null ? "" : el.style.display);
      checkPermission(el, binding.value, vnode);
  },
  update: function(el, binding, vnode) {
      checkPermission(el, binding.value, vnode);
  }
});
Vue.directive('noroles', {
  bind: function(el, binding, vnode) {
      // el.setAttribute("data-initial-display", el.style.display == null ? "" : el.style.display);
      checkNoPermission(el, binding.value, vnode);
  },
  update: function(el, binding, vnode) {
      checkNoPermission(el, binding.value, vnode);
  }
});


let v = new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
  data: {
      user: false,
      token: false
  }
}).$mount('#app')

let token = window.localStorage.getItem('token');
let redirectUrl = findGetParameter('redirectUrl');
v.$root.user = false;
if (token) {
  axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
  Home.me().then(user => v.$root.user = user.dto).catch(() => v.$router.push({ name: 'login', query: { redirectUrl: redirectUrl } }));
} else {
  v.$router.push({ name: 'login', query: { redirectUrl: redirectUrl } });
}

function findGetParameter(parameterName) {
  var result = null,
      tmp = [];
  location.search
      .substr(1)
      .split("&")
      .forEach(function (item) {
        tmp = item.split("=");
        if (tmp[0] === parameterName) result = decodeURIComponent(tmp[1]);
      });
  return result;
}


function checkPermission(el, roles, vnode) {
  let user = window.user;
  if (!user || user == null || !user.roles || (roles && roles.length && roles.filter(r => user.roles.indexOf(r) > -1).length <= 0)) {
    removeElement(el, vnode);
  } 
}
function checkNoPermission(el, roles, vnode) {
  let user = window.user;
  if (!user || user == null || !user.roles || (roles && roles.length && roles.filter(r => user.roles.indexOf(r) > -1).length)) {
    removeElement(el, vnode);
  }
}
function removeElement(el, vnode) {

  // replace HTMLElement with comment node
  const comment = document.createComment(' ');
  Object.defineProperty(comment, 'setAttribute', {
    value: () => undefined,
  });
  vnode.elm = comment;
  vnode.text = ' ';
  vnode.isComment = true;
  vnode.context = undefined;
  vnode.tag = undefined;
  vnode.data.directives = undefined;

  if (vnode.componentInstance) {
    vnode.componentInstance.$el = comment;
  }

  if (el.parentNode) {
    el.parentNode.replaceChild(comment, el);
  }
}
