<template>
  <v-container class="content-documentos">

    <v-card-title v-if="!getDocumentosConNombreOrdenados() || !getDocumentosConNombreOrdenados().length " class="no-documentos">No existen documentos</v-card-title>
    <v-card 
      v-for="(lineadcn, iddn) in getDocumentosConNombreOrdenados()" 
      elevation="3"
      class="mx-5 my-5" 
      width="30%" 
      :key="'documentosConNombre-' + iddn"
    >
      <v-card-title class="nombre-documentos">{{lineadcn.nombre}}</v-card-title>
      <v-card-text>
        <v-row class="row-files">
            <div v-if="!lineadcn.documentos || !lineadcn.documentos.length"
              style="height: 60px;"
            >

            </div>
            <img 
              v-else
              alt=""
              onerror="this.onerror=null; this.src='img/rfiles/notfound.jfif'" 
              height="60px"
              v-for="(doc, iddoc) in lineadcn.documentos"
              :key="'documentosConNombre-' + iddn + '-doc-' + iddoc"
              class="mx-2 cursor-pointer"
              v-title="doc.nombre"
              @click="download(doc)"
              :src="getSrc(doc)"/>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<style scoped>
.nombre-documentos {
  padding-top: 5px;
  color: gray;
}
.no-documentos {
  width: 100%;
  flex-direction: column;
  color: gray;
}
.row-files {
  border: 1px gray dashed;
  padding: 10px;
}
.content-documentos {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  border-top: 1px #f3ecec solid;
  margin-top: 3px;
}
.cursor-pointer {
  cursor: pointer;
}
</style>
<style>
</style>

<script>

import Utils from '../js/Utils'
import AjaxModel from '../js/ajax/AjaxModel'

  export default {
    name: 'Documentos',

    components: {
    },

    data: () => ({
      Utils
      
    }),

    props: {
      gama: Object
    },

    computed: {
      
    },

    watch: {
    },

    mounted: function() {
    },

    methods: {
      download(doc) {
        let docStr = window.btoa(JSON.stringify(doc));
        let token = window.localStorage.getItem('token');
        let baseUrl = AjaxModel.server;
        let url = baseUrl + "core/public/download?token=" + token + "&documento=" + docStr;
        window.open(url, '_blank', 'noreferrer');
      },
      getSrc(doc) {
        switch (doc.extension.toLowerCase()) {
          case "pdf":
            return "img/rfiles/pdf.png";
          case "doc":
          case "docx":
            return "img/rfiles/doc.png";
          case "xls":
          case "xlsx":
            return "img/rfiles/xls.png";
          case "png":
          case "jpg":
          case "jpeg":
          case "jfif":
          case "gif": {
            let docStr = window.btoa(JSON.stringify(doc));
            let token = window.localStorage.getItem('token');
            let baseUrl = AjaxModel.server;
            return baseUrl + "core/public/download?token=" + token + "&documento=" + docStr;
          }
          case "mp4":
          case "mkv":
          case "vob":
          case "avi":
          case "mov":
            return "img/rfiles/video.png";
          case "m4a":
            return "img/rfiles/audio-icon.png";
          default:
            return "img/rfiles/attachment-icon.png";
        }
      },
      getDocumentosConNombreOrdenados() {
        const self = this;
        if (self.gama && self.gama.documentosConNombre) {
          return Object.values(self.gama.documentosConNombre).filter(d => d.visibleCliente).sort((a, b) => { return a.orden - b.orden });
        } else {
          return [];
        }
      }
    }

  }
</script>

