import AjaxModel from './AjaxModel.js';

var Menu = Object.create(AjaxModel);

Menu.model = 'core/config/menu';

Menu.getByCodigo =  function(codigo) {
    var self = this;
    const config = {
        method: 'get',
        url: self.server + self.model + '/codigo/' + codigo,
        headers: { 
          'Content-Type': 'application/json',
        }
    };
    return self.sendRequest(config);
}

export default Menu;