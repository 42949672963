<template>
  <v-list-group
    v-if="item.hijos && item.hijos.length"
    :value="false"
    :prepend-icon="item.icon"
    v-title="item.title"
  >
    <template v-slot:activator>
      <v-list-item-title>{{item.title}}</v-list-item-title>
    </template>
    <menu-item
      v-for="(subitem, idx) in item.hijos"
      :key="item.link + '-' + idx"
      :item="subitem"
      :items="items"
      :parent="item"
    >
    </menu-item>
  </v-list-group>
  <v-list-item
    v-else
    link
    @click.stop="onClickItem(item)"
    :class="{'v-item--active v-list-item--active indigo--text': $route.path == item.link}"
  >
    <v-list-item-icon :class="{'ml-10': !!parent && parent.link == item.link}" v-title="item.title">
      <v-icon>{{ item.icon }}</v-icon>
    </v-list-item-icon>

    <v-list-item-content>
      <v-list-item-title>{{ item.title }}</v-list-item-title>
    </v-list-item-content>

  </v-list-item>
</template>

<script>

  export default {
    name: 'MenuItem',

    data: () => ({
    }),

    components: {
    },

    props: {
      item: Object,
      parent: Object,
      items: {
        type: Array,
        default: function() {
          return [];
        }
      }
    },

    computed: {
    },

    mounted() {
    },

    methods: {
      onClickItem(item) {
        const self = this;
        let path = item.link;
        if (path != self.$route.path || Object.keys(self.$route.query).length) {
          self.$router.push(path)
        }
      },
      tieneHijos() {
        const self = this;
        return !!self.items.filter(i => i.parent == self.item._id).length;
      },
      getHijos() {
        const self = this;
        return self.items.filter(i => i.parent == self.item._id);
      }
    }

  }
</script>

<style scoped>
.cursor-pointer {
  cursor: pointer;
}
</style>
