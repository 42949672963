<template>
  <v-app>
    <v-app-bar
      dense
      app
      color="indigo"
      dark
      v-if="isUser"
    >
      <img src="/logo.png" style="height: inherit;">
      <v-spacer></v-spacer>
      <div class="d-flex align-center">
        
      </div>

      <v-spacer></v-spacer>
      <div>{{buildDate}}</div>
      <v-btn
        text
        @click="cerrarSesion"
      >
        <span class="mr-2">Cerrar Sesión</span>
        <v-icon>mdi-logout</v-icon>
      </v-btn>

    </v-app-bar>

    <Menu v-if="isUser"></Menu>
    <v-main class="ma-5" v-if="isUser || $route.name == 'login'">
      <router-view/>
    </v-main>
  </v-app>
</template>

<script>

import Menu from './components/menu/Menu.vue';

export default {
  name: 'App',

  data: () => ({
    buildDate: process.env.VUE_APP_BUILDDATE
  }),

  components: {
    Menu
  },

  methods: {
    cerrarSesion() {
      const self = this;
      self.$root.token = false;
      self.$root.user = false;
      window.localStorage.removeItem('token');
      self.$router.push({ name: 'login' });
    }
  },
  computed: {
    isUser: function() {
      const self = this;
      return self.$root && self.$root.user;
    }
  },
  mounted: function() {
    const self = this;
    console.log(self.$root);
  }
};
</script>
