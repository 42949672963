import AjaxModel from './AjaxModel.js';

var Home = Object.create(AjaxModel);

Home.model = '';

Home.me =  function() {
    var self = this;
    const config = {
        method: 'get',
        url: self.server + 'me'
    };
    return self.sendRequest(config);
}

export default Home;