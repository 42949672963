<template>
  <v-container class="pral-container">
    <v-row>
      <v-col
        cols="12"
        md="4"
        v-for="item in items"
        :key="'item' + item.valor"
      >
        <v-text-field
          color="#3f51b5"
          hide-details
          dense
          :label="item.label"
          :prefix="item.prefix"
          outlined
          :readonly="true"
          :value="getItemValue(item)"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-card
      v-if="acumuladoEsign && acumuladoEsign > 0 && gama.eSign"
      class="mx-auto text-center mt-5"
      :width="chartWidth"
    >
      <!-- <v-sheet color="rgba(0, 0, 0, .12)"> -->
      <apexchart height="350" type="line" :options="chartOptions" :series="series" class="mx-auto"></apexchart>
      <!-- </v-sheet> -->
    </v-card>
  </v-container>
    <!-- <v-card-text>Datos de la selección</v-card-text> -->
</template>

<style scoped>
.pral-container {
  border-top: 1px #f3ecec solid;
  margin-top: 3px;
}
</style>
<style>
</style>

<script>

import Mongo from '../js/ajax/Mongo'
import Utils from '../js/Utils'
const command = "{aggregate:'lineaHorasGama', pipeline: [ { $match: { 'numeroGama': $$$gamaId } } ], allowDiskUse: true, cursor: {} }";

  export default {
    name: 'DatosSeleccion',

    components: {
    },

    data: () => ({
      Utils,
      acumuladoEsign: 0,
      chartOptions: {
        title: {
          text: "Acumulado"
        },
        chart: {
          id: 'Acumulado_e-sign'
        },
        xaxis: {
          categories: []
        }
      },
      series: [],
      items: [
        {
          label: 'Nombre',
          valor: 'nombre'
        },
        {
          label: 'Referencias',
          valor: 'referencias'
        },
        {
          label: 'Centro trabajo',
          valor: 'centroTrabajo.nombre'
        },
        {
          label: 'Zona de selección',
          valor: 'zonaSeleccion'
        },
        {
          label: 'Fecha Inicio',
          valor: 'fechaInicio',
          type: 'date'
        },
        {
          label: 'Fecha Fin Prevista',
          valor: 'fechaFinPrevista',
          type: 'date'
        },
        {
          label: 'Asunto',
          valor: 'denominacion'
        },
        {
          label: 'Responsable Pago',
          valor: 'centrogasto'
        },
        {
          label: 'Proveedor pieza',
          valor: 'proveedor'
        },
        {
          label: 'Planta proveedor',
          valor: 'plantaProveedor'
        },
        {
          label: 'TQF',
          valor: 'tqf'
        },
        {
          label: 'Defecto',
          valor: 'descripcionDefecto'
        },
        {
          label: 'Peticionario',
          valor: 'nombrePeticionario'
        },
        {
          label: 'Work order',
          valor: 'wo',
          type: 'boolean'
        },
        {
          label: 'Nº E-sign',
          valor: 'eSign',
        },
        {
          label: 'Acumulado',
          valor: 'acumuladoEsign',
          prefix: "€",
          funcValor: function(item, self) {
            // if (!self.gama.eSign) {
            //   return '-';
            // }
            return self.acumuladoEsign;
          }
        },
      ],
      
    }),

    props: {
      gama: Object
    },

    computed: {
      chartWidth() {
        const self = this;
        let maxData = 0;
        self.series.forEach(s => {
          let l = s.data && s.data.length ? s.data.length : 0;
          if (l > maxData) {
            maxData = l;
          }
        });
        if (maxData < 3) {
          maxData = 3;
        }
        return maxData * 200;
      } ,
    },

    watch: {
    },

    mounted: function() {
      const self = this;
      Mongo.runCommand(command.replaceAll('$$$gamaId', "'" + self.gama._id + "'")).then(res => {
          console.log('HORAS', res);
          let horas = res.dto.list;
          
          // let horasCarretilleroDia = 0;
          // let horasCarretilleroDomingoDia = 0;
          // let horasCarretilleroDomingoNoche = 0;
          // let horasCarretilleroFestivoDia = 0;
          // let horasCarretilleroFestivoNoche = 0;
          // let horasCarretilleroNoche = 0;
          // let horasDia = 0;
          // let horasDomingoDia = 0;
          // let horasDomingoNoche = 0;
          // let horasFestivoDia = 0;
          // let horasFestivoNoche = 0;
          // let horasNoche = 0;

          let tarifa = self.gama.tarifa || {};
          let acumTotal = 0;
          let acumMes = {};
          let serie = {
            name: 'Acumulado',
            data: []
          }
          horas.forEach(h => {
            // horasCarretilleroDia += (h.horasCarretilleroDia || 0);
            // horasCarretilleroDomingoDia += (h.horasCarretilleroDomingoDia || 0);
            // horasCarretilleroDomingoNoche += (h.horasCarretilleroDomingoNoche || 0);
            // horasCarretilleroFestivoDia += (h.horasCarretilleroFestivoDia || 0);
            // horasCarretilleroFestivoNoche += (h.horasCarretilleroFestivoNoche || 0);
            // horasCarretilleroNoche += (h.horasCarretilleroNoche || 0);
            // horasDia += (h.horasDia || 0);
            // horasDomingoDia += (h.horasDomingoDia || 0);
            // horasDomingoNoche += (h.horasDomingoNoche || 0);
            // horasFestivoDia += (h.horasFestivoDia || 0);
            // horasFestivoNoche += (h.horasFestivoNoche || 0);
            // horasNoche += (h.horasNoche || 0);
            
            let acum = 0;
            acum += (tarifa.precioCarretilleroDia || 0) * (h.horasCarretilleroDia || 0);
            acum += (tarifa.precioCarretilleroDomingoDia || 0) * (h.horasCarretilleroDomingoDia || 0);
            acum += (tarifa.precioCarretilleroDomingoNoche || 0) * (h.horasCarretilleroDomingoNoche || 0);
            acum += (tarifa.precioCarretilleroFestivoDia || 0) * (h.horasCarretilleroFestivoDia || 0);
            acum += (tarifa.precioCarretilleroFestivoNoche || 0) * (h.horasCarretilleroFestivoNoche || 0);
            acum += (tarifa.precioCarretilleroNoche || 0) * (h.horasCarretilleroNoche || 0);
            acum += (tarifa.precioDia || 0) * (h.horasDia || 0);
            acum += (tarifa.precioDomingoDia || 0) * (h.horasDomingoDia || 0);
            acum += (tarifa.precioDomingoNoche || 0) * (h.horasDomingoNoche || 0);
            acum += (tarifa.precioFestivoDia || 0) * (h.horasFestivoDia || 0);
            acum += (tarifa.precioFestivoNoche || 0) * (h.horasFestivoNoche || 0);
            acum += (tarifa.precioNoche || 0) * (h.horasNoche || 0);

            let mes = self.$moment(h.fecha).format("MMMM");
            let mesNumero = self.$moment(h.fecha).format("M");

            acumMes[mesNumero] = {
              nombre: mes,
              valor: (acumMes[mesNumero] ? acumMes[mesNumero].valor : 0) + acum
            };

            acumTotal += acum;
          });

          console.log("acumMes", acumMes);
          
          // let acum = 0;
          // acum += (tarifa.precioCarretilleroDia || 0) * horasCarretilleroDia;
          // acum += (tarifa.precioCarretilleroDomingoDia || 0) * horasCarretilleroDomingoDia;
          // acum += (tarifa.precioCarretilleroDomingoNoche || 0) * horasCarretilleroDomingoNoche;
          // acum += (tarifa.precioCarretilleroFestivoDia || 0) * horasCarretilleroFestivoDia;
          // acum += (tarifa.precioCarretilleroFestivoNoche || 0) * horasCarretilleroFestivoNoche;
          // acum += (tarifa.precioCarretilleroNoche || 0) * horasCarretilleroNoche;
          // acum += (tarifa.precioDia || 0) * horasDia;
          // acum += (tarifa.precioDomingoDia || 0) * horasDomingoDia;
          // acum += (tarifa.precioDomingoNoche || 0) * horasDomingoNoche;
          // acum += (tarifa.precioFestivoDia || 0) * horasFestivoDia;
          // acum += (tarifa.precioFestivoNoche || 0) * horasFestivoNoche;
          // acum += (tarifa.precioNoche || 0) * horasNoche;
          Object.keys(acumMes).forEach(k => {
            let acum = acumMes[k];
            serie.data.push(acum.valor.toFixed(2));
            self.chartOptions.xaxis.categories.push(self.capitalizeFirstLetter(acum.nombre));
          })
          self.series.push(serie);
          self.acumuladoEsign = acumTotal.toFixed(2);
        }).catch(() => self.loading = false);
    },

    methods: {
      capitalizeFirstLetter(string) {
          return string.charAt(0).toUpperCase() + string.slice(1);
      },
      getItemValue(item) {
        const self = this;
        if (item.funcValor) {
          return item.funcValor(item, self);
        }
        let value = self.Utils.getNamespaceValue(self.gama, item.valor);
        if (typeof value == 'undefined' || value == null || (typeof value == 'string' && value == '')) {
            return ' ';
        }
        if (item.type && item.type == 'date') {
            value = self.$moment(value).format('DD/MM/YYYY');
        } else if (item.type && item.type == 'boolean') {
          if (value) {
            value = 'Sí';
          } else {
            value = 'No';
          }
        }
        return value;
      }
    }

  }
</script>

