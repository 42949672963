<template>
  <v-data-table
    :headers="headers"
    :items="items"
    :search="search"
    :custom-filter="Utils.customFilter"
    class="elevation-1"
    dense
    :loading="loading"
    loading-text="Cargando datos..." 
  >
    <template v-slot:top>
      <div class="d-flex">
        <v-text-field
          v-model="search"
          label="Buscar"
          class="mx-4"
        ></v-text-field>
        <v-btn
          icon
          color="#3f51b5"
          @click="exportarExcel"
        >
          <v-icon>mdi-file-export-outline</v-icon>
        </v-btn>
      </div>
    </template>
    <template v-slot:item="item">
      <tr>
        <td v-for="header in item.headers"
          :key="'phoenix-value-' + item.index + '-' + header.value"
          :class="'style-' + header.tipo"
        >
          <template>
            {{Utils.getItemValue(item.item, header)}}
          </template>
        </td>
      </tr>
    </template>
    <template v-slot:no-data>
      No hay envíos a mostrar
    </template>
  </v-data-table>
</template>

<style scoped>
</style>
<style>
</style>

<script>

import Mongo from '../js/ajax/Mongo'
import Utils from '../js/Utils'
import xlsx from "json-as-xlsx"
const command = "{aggregate:'lineaValeGama', pipeline: [ { $match: { 'numeroGama': $$$gamaId } } ], allowDiskUse: true, cursor: {} }";

  export default {
    name: 'Phoenix',

    components: {
    },

    data: () => ({
      Utils,
      items: [],
      search: '',
      loading: false,
      headers: [
        { text: 'Nº Vale', value: 'numeroVale', sortable: true },
        { text: 'Referencia', value: 'referencia', sortable: true },
        { text: 'Motivo', value: 'motivo', sortable: true },
        { text: 'Cantidad', value: 'cantidad', sortable: true, tipo: 'number', decimales: 2 },
        { text: 'Turno', value: 'turno', sortable: true },
        { text: 'Lugar Detección', value: 'lugarDetección', sortable: true },
        { text: 'Movimiento', value: 'movimiento', sortable: true},
        { text: 'Días restantes', value: 'diasRestantes', sortable: true, tipo: 'number', decimales: 0 },
        { text: 'Fecha Dictamen', value: 'fechaDictamen', sortable: true, tipo: 'date', formato: 'DD/MM/YYYY' },
        { text: 'Días dictamen', value: 'diasDictamen', sortable: true, tipo: 'number', decimales: 0 },
        { text: 'Agrupación', value: 'agrupacion', sortable: true },
      ]
    }),

    props: {
      gamaId: String
    },

    computed: {
      
    },

    watch: {
      gamaId() {
        const self = this;
        self.loadData();
      }
    },

    mounted: function() {
      const self = this;
      self.loadData();
    },

    methods: {
      loadData() {
        const self = this;
        self.items = [];
        self.loading = true;
        Mongo.runCommand(command.replaceAll('$$$gamaId', "'" + self.gamaId + "'")).then(res => {
          console.log("PHOENIX", res);
          self.items = res.dto.list;
          self.loading = false;
        }).catch(() => self.loading = false);
      },
      exportarExcel() {
        const self = this;
        if (self.gamaId && self.headers) {
          let headers = self.headers.filter(h => h.value != 'actions').map(h => {
            return {
              label: h.text,
              value: (item) => Utils.getItemValue(item, h)
            }
          });
          let settings = {
            fileName: self.gamaId + ' - Vales Phoenix',
            extraLength: 3, // A bigger number means that columns will be wider
            writeMode: "writeFile", // The available parameters are 'WriteFile' and 'write'. This setting is optional. Useful in such cases https://docs.sheetjs.com/docs/solutions/output#example-remote-file
            writeOptions: {}, // Style options from https://docs.sheetjs.com/docs/api/write-options
            RTL: false, // Display the columns from right-to-left (the default value is false)
          };
          let data = 
          [
            {
              sheet: "Vales Phoenix",
              columns: headers,
              content: self.items
            }
          ];
          xlsx(data, settings);
        }
      },
    }

  }
</script>

