import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import GamaView from '../views/GamaView.vue'
import ListView from '../views/ListView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/gamas/:gamaId',
    name: 'gama',
    component: GamaView,
    props: true
  },
  {
    path: '/gamas/:gamaId/:esCampa',
    name: 'gamaCampa',
    component: GamaView,
    props: true
  },
  {
    path: '/list/:contadorId',
    name: 'list',
    component: ListView,
    props: true
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/LoginView.vue')
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router
