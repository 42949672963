<template>
  <v-data-table
    :headers="headers"
    :items="items"
    :search="search"
    :custom-filter="Utils.customFilter"
    class="elevation-1"
    dense
    :loading="loading"
    loading-text="Cargando datos..."
  >
    <template v-slot:top>
      <v-text-field
        v-model="search"
        label="Buscar"
        class="mx-4"
      ></v-text-field>
    </template>
    <template v-slot:item="item">
      <tr>
        <td v-for="header in item.headers"
          :key="'piezasnok-value-' + item.index + '-' + header.value"
          :class="'style-' + header.tipo"
        >
          <template>
            {{Utils.getItemValue(item.item, header)}}
          </template>
        </td>
      </tr>
    </template>
    <template v-slot:no-data>
      No hay piezas a mostrar
    </template>
    <template v-slot:foot>
      <tfoot>
        <tr>
            <th style="font-size: 20px;">Total</th>
            <th style="font-size: 20px;">{{getSum('ok')}}</th>
            <th style="font-size: 20px;">{{getSum('nok')}}</th>
            <th style="font-size: 20px;">{{getSum('funda')}}</th>
            <th style="font-size: 20px;">{{getSum('ausente')}}</th>
            <th style="font-size: 20px;">{{getSum('noarranca')}}</th>
            <th style="font-size: 20px;">{{getSum('otro')}}</th>
        </tr>
      </tfoot>
    </template>
  </v-data-table>
</template>

<style scoped>
</style>
<style>
</style>

<script>

import Mongo from '../js/ajax/Mongo'
import Utils from '../js/Utils'
const command = "{aggregate:'gama', pipeline: [{$match: {$expr: {$and: [{ $eq: [ '$_id', $$$gamaId ] }]}}},{$lookup: {from: 'inspeccionCampa',localField: '_id',foreignField: 'gamas.numero',as: 'campa'}},{$unwind: '$campa'},{$unwind: '$campa.inspeccionesCoches'},{$addFields: {'yearMonthDay': { $dateToString: { format: '%Y-%m-%d', date: '$campa.fecha' } }}},{$group: {'_id': '$yearMonthDay','fecha': {$first: '$campa.fecha'},'defectos': {$last: '$campa.inspeccionesCoches.defectos'},'ok': {'$sum': {'$cond': [{'$eq': ['$campa.inspeccionesCoches.resultado','OK']},1,0]}},'nok': {'$sum': {'$cond': [{'$and': [{'$eq': ['$campa.inspeccionesCoches.resultado','NOK']},{'$in': ['$_id','$campa.inspeccionesCoches.defectos.numeroGama']}]},1,0]}},'funda': {'$sum': {'$cond': [{'$and': [{'$eq': ['$campa.inspeccionesCoches.resultado','NOK']},{'$in': ['CONFUNDA','$campa.inspeccionesCoches.defectos.numeroGama']}]},1,0]}},'ausente': {'$sum': {'$cond': [{'$and': [{'$eq': ['$campa.inspeccionesCoches.resultado','NOK']},{'$in': ['NOESTA','$campa.inspeccionesCoches.defectos.numeroGama']}]},1,0]}},'otro': {'$sum': {'$cond': [{'$and': [{'$eq': ['$campa.inspeccionesCoches.resultado','NOK']},{'$in': ['OTROS','$campa.inspeccionesCoches.defectos.numeroGama']}]},1,0]}},'noarranca': {'$sum': {'$cond': [{'$and': [{'$eq': ['$campa.inspeccionesCoches.resultado','NOK']},{'$in': ['NOARRANCA','$campa.inspeccionesCoches.defectos.numeroGama']}]},1,0]}}}},{$sort: {'fecha': 1}}], allowDiskUse: true, cursor: {}}";

  export default {
    name: 'DatosCampa',

    components: {
    },

    data: () => ({
      Utils,
      items: [],
      search: '',
      loading: false,
      headers: [
        { text: 'Fecha', value: 'fecha', sortable: true, tipo: 'date', formato: 'DD/MM/YYYY' },
        { text: 'OK', value: 'ok', sortable: true},
        { text: 'NOK', value: 'nok', sortable: true},
        { text: 'Con funda', value: 'funda', sortable: true },
        { text: 'No está', value: 'ausente', sortable: true },
        { text: 'No arranca', value: 'noarranca', sortable: true },
        { text: 'Otro', value: 'otro', sortable: true }
      ],
    }),

    props: {
      gamaId: String
    },

    computed: {
      
    },

    watch: {
      gamaId() {
        const self = this;
        self.loadData();
      }
    },

    mounted: function() {
      const self = this;
      self.loadData();
    },

    methods: {
      getSum(prop) {
        let sum = 0; 
        const self = this;
        self.items.forEach(i => sum += i[prop]);
        return sum;
      },
      loadData() {
        const self = this;
        self.items = [];
        self.loading = true;
        Mongo.runCommand(command.replaceAll('$$$gamaId', "'" + self.gamaId + "'")).then(res => {
          self.items = res.dto.list;
          self.loading = false;
        }).catch(() => self.loading = false);
      },
    }
  }
</script>

