<template>
  <v-card class="h-100">
      <div class="d-flex">
        <v-btn
          text
          color="red"
          @click="volver"
        >
          &lt; Volver
        </v-btn>
        <v-spacer></v-spacer>
        <v-card-title class="text-center">
          <div class="title">
            Selección {{gamaId}} ({{gama.estado && gama.estado.estado && gama.estado.estado == 'CERRADA' ? 'Cerrada' : 'Abierta'}})
          </div>
        </v-card-title>
        <v-spacer></v-spacer>
      </div>

    <v-tabs
      v-model="tab"
      background-color="transparent"
      color="#3f51b5"
      grow
    >
      <v-tab key="datos">Datos de la selección</v-tab>
      <v-tab key="campa" v-if="esCampa">Inspecciones en campa</v-tab>
      <v-tab key="nok" v-if="!esCampa">Piezas NOK</v-tab>
      <v-tab key="totales" v-if="!esCampa">Resultados totales</v-tab>
      <v-tab key="envios" v-if="!esCampa">Envíos</v-tab>
      <v-tab key="phoenix" v-if="!esCampa">Vales Phoenix</v-tab>
      <v-tab key="documentos">Documentos</v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <!-- Datos de la selección -->
      <v-tab-item key="datos">
        <datos-seleccion v-if="gama" :gama="gama"></datos-seleccion>
      </v-tab-item>
      <!-- Inspecciones de campa -->
      <v-tab-item key="campa" v-if="esCampa">
        <datos-campa :gamaId="gamaId"></datos-campa>
      </v-tab-item>
      <!-- Piezas NOK -->
      <v-tab-item key="nok" v-if="!esCampa">
        <piezas-nok :gamaId="gamaId"></piezas-nok>
      </v-tab-item>
      <!-- Resultados totales -->
      <v-tab-item key="totales" v-if="!esCampa">
        <resultados-totales :gamaId="gamaId"></resultados-totales>
      </v-tab-item>
      <!-- Envíos -->
      <v-tab-item key="envios" v-if="!esCampa">
        <envios :gamaId="gamaId"></envios>
      </v-tab-item>
      <!-- Vales Phoenix -->
      <v-tab-item key="phoenix" v-if="!esCampa">
        <phoenix :gamaId="gamaId"></phoenix>
      </v-tab-item>
      <v-tab-item key="documentos">
        <documentos v-if="gama" :gama="gama"></documentos>
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>

<script>

import Mongo from '../js/ajax/Mongo'
import DatosSeleccion from '../components/DatosSeleccion.vue'
import Envios from '../components/Envios.vue'
import Phoenix from '../components/Phoenix.vue'
import PiezasNok from '../components/PiezasNok.vue'
import ResultadosTotales from '../components/ResultadosTotales.vue'
import DatosCampa from '../components/DatosCampa.vue'
import Documentos from '../components/Documentos.vue'
const command = "{aggregate:'gama', pipeline: [ { $match: { '_id': $$$gamaId } } ], allowDiskUse: true, cursor: {} }";

  export default {
    name: 'Gama',

    components: {
      DatosSeleccion,
      Envios,
      PiezasNok,
      ResultadosTotales,
      DatosCampa,
      Phoenix,
      Documentos
    },

    data: () => ({
      gama: false,
      tab: 0
    }),
    methods: {
      volver() {
        const self = this;
        self.$router.back();
      },
    },
    mounted: function() {
      const self = this;
      let queryData = self.$route.query.data;
      if (queryData) {
        queryData = window.atob(queryData);
      }
      if (queryData) {
        queryData = JSON.parse(queryData) || '';
      }
      if (queryData && queryData.tab) {
        self.tab = queryData.tab;
      }
      Mongo.runCommand(command.replaceAll('$$$gamaId', "'" + self.gamaId + "'")).then(res => {
        console.log("GAMA", res);
        self.gama = res.dto.list[0];
      });
    },
    props: {
      gamaId: String,
      esCampa: String
    }
  }
</script>

<style scoped>

.title {
  color: #3f51b5;
}
.h-100 {
  height: 100%;
}

/* .container-data {
  margin: 20px;
} */

@media only screen and (max-width: 600px) {



}
</style>
