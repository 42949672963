<template>
  <div class="listview" v-if="selectedDate">
    <div class="d-flex">
      <v-btn
        text
        color="red"
        @click="volver"
      >
        &lt; Volver
      </v-btn>
      <v-spacer></v-spacer>
      <h3 class="text-center list-title">{{contador.nombre + (contador.dateInTitle ? ' día ' + $moment(selectedDate,'YYYY-MM-DD').format('DD/MM/YYYY') : '')}}</h3>
      <v-spacer></v-spacer>
      <v-btn
        icon
        color="#3f51b5"
        @click="exportarExcel"
      >
        <v-icon>mdi-file-export-outline</v-icon>
      </v-btn>
    </div>
    <v-data-table
      :headers="headers"
      :items="items"
      :search="search"
      class="elevation-1"
      dense
      :loading="loading"
      loading-text="Cargando datos..."
      :sort-by="contador.sortBy"
      :sort-desc="contador.sortDesc || false"
    >
      <template v-slot:header>
        <tr>
          <td v-for="header in headers" :key="'item-header-' + header.value" v-show="true">
            <template v-if="header.value != 'actions' && header.tipo == 'select'">
              <v-select
                :items="searchOptions(header.value)"
                filled
                class="mr-1" dense
                v-model="buscador[header.value]"
                clearable
                multiple
                chips
                return-object
              >
              </v-select>
            </template>
            <v-text-field 
              v-if="header.value != 'actions' && header.tipo != 'select'" 
              class="mr-1" dense filled :placeholder="''"
              v-model="buscador[header.value]"
              :clearable="true"
            />
            <!-- <v-icon class="mb-5 ml-3" :color="Object.keys(buscador).length ? 'red' : undefined" @click="Object.keys(buscador).length ? buscador={} : undefined" v-else >mdi-cancel</v-icon> -->
          </td>
        </tr>
      </template>
      <template v-slot:item="item">
        <tr>
          <td v-for="header in item.headers"
            :key="'item-value-' + item.index + '-' + header.value"
            :class="'style-' + header.tipo"
            :style="header.style ? header.style : null"
            @click="header.click ? execEvalValue(header.click, item.item) : null"
          >
            <template v-if="header.value == 'actions'">
              <v-icon
                small
                v-if="contador.detalleView"
                @click="detalle(item.item)"
              >
                mdi-eye
              </v-icon>
            </template>
            <template v-else>
              {{Utils.getItemValue(item.item, header)}}
            </template>
          </td>
        </tr>
      </template>
      <template v-slot:no-data>
        No hay datos a mostrar
      </template>
    </v-data-table>
  </div>
</template>

<style scoped>
  .list-title {
    color: #3f51b5;
  }
</style>
<style>
</style>

<script>

import Mongo from '../js/ajax/Mongo'
import Utils from '../js/Utils'
import xlsx from "json-as-xlsx"

  export default {
    name: 'ListView',

    components: {
    },

    data: () => ({
      Utils,
      items: [],
      search: '',
      contador: false,
      loading: false,
      selectedDate: '',
      buscador: {}
    }),

    props: {
      // contador: {
      //   type: Object,
      //   default: function() {
      //     return {};
      //   }
      // },
      contadorId: String
    },

    computed: {
      headers() {
        const self = this;
        let res = [];
        if (self.contador && self.contador.headers && self.contador.headers.length) {
          let headers = self.contador.headers.map(h => {
            h.filter = (v, s) => self.customFilter(v, s, h);
            return h;
          });
          return headers;
        } 
        return res;
      }
    },

    watch: {
    },

    mounted: function() {
      const self = this;
      let contadores = JSON.parse(localStorage.getItem('contadores'));
      self.contador = contadores.filter(c => c.id == self.contadorId)[0];
      if (self.contador) {
        self.loadData();
      }
    },

    methods: {
      customFilter(value, search, header) {
        const self = this;
        console.log("customFilter", value, search, header);
        if (!header || header.value == 'actions') {
            return true;
        }
        search = self.buscador[header.value];
        if (!search || (Array.isArray(search) && search.length == 0)) {
          return true;
        } else if (!Array.isArray(search) || search.length == 1) {
          search = search.toString().toLowerCase().trim();
        }
        if (!value) {
          return false;
        }
        let val = value;
        if (header && header.tipo && header.tipo == 'date') {
            let valMoment = self.$moment(val);
            if (valMoment && valMoment.isValid()) {
              val = valMoment.format("DD/MM/YYYY");
            }
        }
        if (Array.isArray(search)) {
          return search.includes(val);
        } else {
          return val.toString().toLowerCase().includes(search);
        }
      },
      searchOptions(campo) {
        const self = this;
        let res = [];
        // Vamos a extraer los diferentes valores que hay en el campo de la lista de items
        if (self.items && self.items.length) {
          let valores = self.items.map(i => Utils.getItemValue(i, {value: campo}));
          res = [...new Set(valores)].filter((v, i, a) => v && a.indexOf(v) === i).sort();
        }
        return res;
      },
      execEvalValue(str, item) {
        const self = this;
        console.log(self);
        console.log(item);
        eval(str);
      },
      exportarExcel() {
        const self = this;
        if (self.contador && self.contador.headers) {
          let headers = self.contador.headers.filter(h => h.value != 'actions').map(h => {
            return {
              label: h.text,
              value: (item) => Utils.getItemValue(item, h)
            }
          });
          let settings = {
            fileName: self.contador.nombre + (self.contador.dateInTitle ? ' - ' + self.selectedDate : ''),
            extraLength: 3, // A bigger number means that columns will be wider
            writeMode: "writeFile", // The available parameters are 'WriteFile' and 'write'. This setting is optional. Useful in such cases https://docs.sheetjs.com/docs/solutions/output#example-remote-file
            writeOptions: {}, // Style options from https://docs.sheetjs.com/docs/api/write-options
            RTL: false, // Display the columns from right-to-left (the default value is false)
          };
          let data = 
          [
            {
              sheet: "Listado",
              columns: headers,
              content: self.items
            }
          ];
          xlsx(data, settings);
        }
      },
      volver() {
        const self = this;
        self.$router.back();
      },
      detalle(item) {
        const self = this;
        let params = false;
        console.log(item);
        // Añadimos al valor base64 del parámetro data de la url los filtros que se hayan aplicado
        if (self.buscador && Object.keys(self.buscador).length) {
          // params = {data: window.btoa(JSON.stringify({selectedDate: self.selectedDate, buscador: self.buscador}))};

          let queryData = self.$route.query.data;
          if (queryData) {queryData = window.atob(queryData);}
          if (queryData) {queryData = JSON.parse(queryData) || '';} else {queryData = {};}
          queryData.buscador = self.buscador;
          let data = JSON.stringify(queryData);
          // reemplazamos la última url del historial de navegación
          self.$router.replace({name: 'list', contadorId: self.contador.id, query: {data: window.btoa(data)}});
          // self.$router.push({name: 'list', contadorId: self.contador.id, query: {data: window.btoa(data)}});
        }
        // ----------------------------------------------------------------------------------------------------
        if (self.contador.detalleParams) {
          params = JSON.parse(JSON.stringify(self.contador.detalleParams));
          Object.keys(params).forEach(k => {
            let pattern = typeof params[k] != 'string' ? undefined : params[k].match(/\$\{([^)]+)\}/);
            if (pattern) {
              let replaceWith = pattern[1];
              if (replaceWith) {
                params[k] = params[k].replaceAll('${' + replaceWith + '}', item[replaceWith]);
              }
            }
          });
        }
        self.$router.push({ name: self.contador.detalleView, params: params })
      },
      loadData() {
        const self = this;
        self.items = [];
        self.loading = true;
        let queryData = self.$route.query.data;
        if (queryData) {
          queryData = window.atob(queryData);
        }
        if (queryData) {
          queryData = JSON.parse(queryData) || '';
        }
        if (queryData && queryData.selectedDate) {
          self.selectedDate = queryData.selectedDate;
        }
        let centrosSeleccionados = [];
        if (queryData && queryData.centrosTrabajo) {
          centrosSeleccionados = queryData.centrosTrabajo;
        }
        if (queryData && queryData.buscador) {
          self.buscador = queryData.buscador;
        }
        let centrosSeleccionadosStr = "['NO_EXISTE']";
        // Si el usuario no tiene centros de trabajo asignados, no vamos a mostrarle nada.
        if (!self.$root.user || !self.$root.user.datosEmpleado || !self.$root.user.datosEmpleado.centrosTrabajo || !self.$root.user.datosEmpleado.centrosTrabajo.length) {
          self.contadores = [];
          return;
        }
        // Si no tenemos centros seleccionados, buscaremos todos los centros del usuario y los añadiremos
        if ((!centrosSeleccionados || centrosSeleccionados.length === 0) && self.$root.user.datosEmpleado.centrosTrabajo) {
          centrosSeleccionadosStr = JSON.stringify(self.$root.user.datosEmpleado.centrosTrabajo.map(centro => centro.codigo));
        } else {
          centrosSeleccionadosStr = JSON.stringify(centrosSeleccionados.map(centro => centro.codigo));
        }
        Mongo.runCommand(self.contador.listado.replaceAll('$$$selectedDate', "'" + self.selectedDate + "'").replaceAll('$$$centrosTrabajo', centrosSeleccionadosStr)).then(res => {
          self.items = res.dto.list;
          self.loading = false;
        }).catch(() => self.loading = false);
      },
    }

  }
</script>

